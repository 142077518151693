import Link from 'next/link';
import React from 'react';

import styles from '../styles/Footer.module.css';
import Logo from './Logo';
import SocialNav from './SocialNav';
import Btn from './ui/Button';
import Container from './ui/Container';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container size="lg">
        <div className={styles.first}>
          <div className={styles.col1}>
            <Logo color="light" width={120} />
            <SocialNav />
          </div>

          <div className={styles.col2}>
            <nav>
              <h3>London Meeting Rooms</h3>
              <ul>
                <li>
                  <Link href="/meeting-rooms/london-blackfriars/">
                    &gt; Blackfriars
                  </Link>
                </li>
                <li>
                  <Link href="/meeting-rooms/london-st-pauls/">
                    &gt; St. Pauls
                  </Link>
                </li>
                <li>
                  <Link href="/meeting-rooms/london-liverpool-street/">
                    &gt; Liverpool St
                  </Link>
                </li>
                <li>
                  <Link href="/meeting-rooms/london-waterloo/">
                    &gt; Waterloo
                  </Link>
                </li>
                <li>
                  <Link href="/meeting-rooms/london-monument/">
                    &gt; Monument
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className={styles.col3}>
            <nav>
              <h3>Regional Meeting Rooms</h3>
              <ul>
                <li>
                  <Link href="/meeting-rooms/cardiff">&gt; Cardiff</Link>
                </li>
                <li>
                  <Link href="/meeting-rooms/edinburgh">&gt; Edinburgh</Link>
                </li>
              </ul>

              <Btn
                className="mt-8"
                color="secondary"
                href="https://osit.officernd.com/public/calendar/"
              >
                Book online
              </Btn>
            </nav>
          </div>

          <div className={styles.col4}>
            <h3>Contact Info</h3>
            <address>
              20 St Dunstan`s Hill
              <br />
              London, EC3R 8HL
            </address>
            <p>
              <span className={styles.highlight}>Phone: </span>
              <br /> <Link href="tel:+448444121796">0844 412 1796</Link>
            </p>
            <p>
              <span className={styles.highlight}>Email:</span>
              <br />
              <Link href="mailto:sales@officespaceintown.com">
                sales@officespaceintown.com
              </Link>
            </p>
          </div>
        </div>

        <div className={styles.final}>
          <div>
            Copyright &copy; {new Date().getFullYear()}{' '}
            <span className={styles.highlight}>OSiT</span>. All Rights Reserved.
          </div>

          <div>
            <Link href="/terms-use">Terms of Use</Link> |{' '}
            <Link href="/privacy-policy">Privacy Policy</Link> |{' '}
            <Link href="/cookies-policy">Cookies Policy</Link>
          </div>
        </div>
      </Container>
    </footer>
  );
}
