import Link from 'next/link';

import styles from '../styles/SocialNav.module.css';
import IconFacebookCircle from './icons/IconFacebookCircle';
import IconInstagram from './icons/IconInstagram';
import IconLinkedIn from './icons/IconLinkedIn';
import IconTwitter from './icons/IconTwitter';

type Props = {
  size?: number;
  facebookActive?: boolean;
  instagramActive?: boolean;
  linkedinActive?: boolean;
  twitterActive?: boolean;
};

export default function SocialNav({
  size = 24,
  facebookActive = true,
  instagramActive = true,
  linkedinActive = true,
  twitterActive = true,
}: Props) {
  return (
    <div className={styles.wrap}>
      {facebookActive && (
        <Link href="https://www.facebook.com/OfficeSpaceInTown/" aria-label="Facebook Social Icon" target="_blank" rel="noopener">
          <IconFacebookCircle width={size} height={size} />
        </Link>
      )}
      {instagramActive && (
        <Link href="https://www.instagram.com/officespaceintown/" aria-label="Instagram Social Icon" target="_blank" rel="noopener">
          <IconInstagram width={size} height={size} />
        </Link>
      )}
      {linkedinActive && (
        <Link href="http://www.linkedin.com/company/office-space-in-town" aria-label="LinkedIn Social Icon" target="_blank" rel="noopener">
          <IconLinkedIn width={size} height={size} />
        </Link>
      )}
      {twitterActive && (
        <Link href="https://twitter.com/OSiTown" aria-label="Twitter Social Icon" target="_blank" rel="noopener">
          <IconTwitter width={size} height={size} />
        </Link>
      )}
    </div>
  );
}
