import Head from 'next/head';
import { useRouter } from 'next/router';
import { OrganizationJsonLd } from 'next-seo';

export const WEBSITE_URL = process.env.NEXT_PUBLIC_APP_URL || 'https://meet-here.co.uk';

type Props = {
  title?: string;
  description?: string;
};

export default function Meta({ title, description }: Props) {
  const router = useRouter();
  const canonicalUrl = (
    WEBSITE_URL + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0];

  const defaultMeta = {
    title: process.env.NEXT_PUBLIC_APP_NAME,
    long: ' | Office Space in Town',
    short: ' | OSiT',
    description:
      'At Office Space in Town UK we offer serviced offices, conference rooms and virtual offices throughout the UK. Flexible fully serviced office to rent.',
    canonical: canonicalUrl,
  };

  const getTitle = () => {
    if (title) {
      if (`${title}${defaultMeta.long}`.length <= 60) {
        return `${title}${defaultMeta.long}`;
      }

      if (`${title}${defaultMeta.short}`.length <= 60) {
        return `${title}${defaultMeta.short}`;
      }

      return title;
    }
    return defaultMeta.title;
  };

  const getDescription = () => {
    if (description) {
      if (description.length <= 160) {
        return description;
      }
      return `${description.slice(0, 157)}...`;
    }
    return defaultMeta.description;
  };

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />

        <title>{getTitle()}</title>
        <meta name="description" content={getDescription()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getDescription()} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@OSiTown" />
        <meta name="twitter:creator" content="@OSiTown" />
        <meta property="og:url" content={WEBSITE_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="SiteName" />
        <link rel="canonical" href={WEBSITE_URL} />
        <meta name="application-name" content="Office Space in Town" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${router.basePath}/android-chrome-192x192.png`}
          key="icon192"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          crossOrigin="use-credentials"
          rel="manifest"
          href={`${router.basePath}/site.webmanifest`}
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
        <link
          rel="mask-icon"
          color="#105477"
          href={`${router.basePath}/safari-pinned-tab.svg`}
        />
        <meta
          name="msapplication-TileImage"
          content={`${router.basePath}/mstile-144x144.png`}
          key="mstile"
        />
        <meta
          name="msapplication-config"
          content={`${router.basePath}/ieconfig.xml`}
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <OrganizationJsonLd
        type="Corporation"
        name={process.env.NEXT_PUBLIC_APP_NAME}
        legalName="Office Space in Town Limited"
        url={process.env.NEXT_PUBLIC_APP_URL}
        logo={`${process.env.NEXT_PUBLIC_APP_URL}/logo.svg`}
        address={{
          streetAddress: 'Castle Court, 6 Cathedral Road',
          addressLocality: 'Cardiff',
          postalCode: 'CF11 9LJ',
          addressCountry: 'GB',
        }}
        contactPoint={[
          {
            telephone: '+44-844-412-1796',
            contactType: 'customer service',
            areaServed: 'GB',
            availableLanguage: 'English',
          },
        ]}
      />
    </>
  );
}
