import cn from 'classnames';
import type { LinkProps } from 'next/link';
import Link from 'next/link';
import React, { forwardRef } from 'react';

import styles from '../../styles/Button.module.css';

interface ISVGProps {
  fill: string;
  height: number;
  width: number;
}
type SVGComponent = React.FunctionComponent<React.SVGProps<ISVGProps>>;

type ButtonProps = {
  color?: 'primary' | 'secondary';
  size?: 'base' | 'sm' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  StartIcon?: SVGComponent;
  shallow?: boolean;
} & (
  | (Omit<JSX.IntrinsicElements['a'], 'href'> & { href: LinkProps['href'] })
  | (JSX.IntrinsicElements['button'] & { href?: never })
);

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, forwardedRef) => {
    const {
      loading = false,
      color = 'primary',
      size = 'base',
      StartIcon,
      shallow,
      // attributes propagated from `HTMLAnchorProps` or `HTMLButtonProps`
      ...passThroughProps
    } = props;
    // Buttons are **always** disabled if we're in a `loading` state
    const disabled = props.disabled || loading;

    // If pass an `href`-attr is passed it's `<a>`, otherwise it's a `<button />`
    const isLink = typeof props.href !== 'undefined';
    const elementType = isLink ? 'a' : 'button';

    const element = React.createElement(
      elementType,
      {
        ...passThroughProps,
        disabled,
        ref: forwardedRef,
        className: cn(
          styles.btn,

          // different styles depending on size
          size === 'sm' && styles.sm,
          size === 'base' && styles.base,
          size === 'lg' && styles.lg,

          // different styles depending on color
          color === 'primary' && (disabled ? styles.disabled : styles.primary),
          color === 'secondary'
          && (disabled ? styles.disabled : styles.secondary),

          // set not-allowed cursor if disabled
          loading ? styles.loading : '',

          // add icon styles
          StartIcon ? styles.icon : '',
          props.className
        ),
        // if we click a disabled button, we prevent going through the click handler
        onClick: disabled
          ? (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();
          }
          : props.onClick,
      },
      <>
        {StartIcon && <StartIcon className="inline w-5 h-5 mr-2 -ml-1" />}
        {props.children}
        {loading && (
          <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <svg
              className="w-5 h-5 mx-4 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
      </>
    );
    return props.href ? (
      <Link passHref href={props.href} shallow={shallow && shallow} legacyBehavior>
        {element}
      </Link>
    ) : (
      element
    );
  }
);

export default Button;
