import cn from 'classnames';
import Link from 'next/link';
import { useState } from 'react';

import styles from '../styles/Header.module.css';
import IconEmail from './icons/IconEmail';
import IconHome from './icons/IconHome';
import IconPhone from './icons/IconPhone';
import IconPhoneSquare from './icons/IconPhoneSquare';
import Logo from './Logo';
import Nav from './Nav';
import SocialNav from './SocialNav';
import Container from './ui/Container';

export type Props = {};

export default function Header() {
  const [contactActive, setContactActive] = useState<boolean>(false);

  const toggleContact = () => {
    setContactActive(!contactActive);
  };

  return (
    <header className={styles.header}>
      <Container size="xl" className="md:flex md:justify-between">
        <Link href="/"
          className={styles.logo_wrap}
          aria-label="Office Space in Town Logo"
        >
          <Logo width={165} />
        </Link>

        <div className={styles.nav}>
          <button
            className={styles.contact_btn}
            onClick={() => {
              toggleContact();
            }}
          >
            <IconPhone width={20} height={20} />
            <span>CONTACT</span>
          </button>

          <div
            className={cn(
              styles.nav_top,
              contactActive ? styles.nav_top_active : undefined
            )}
          >
            <Link href="/" className={styles.nav_top_home} aria-label="Home Icon">
              <IconHome width={24} height={24} />
            </Link>

            <Link href="tel:08444121796" className={styles.nav_top_link} target="_blank">
              <IconPhoneSquare width={30} height={30} />
              <span>0844 412 1796</span>
            </Link>

            <Link href="mailto:sales@officespaceintown.com" className={styles.nav_top_link} target="_blank">
              <IconEmail width={30} height={30} />
              <span>sales@officespaceintown.com</span>
            </Link>

            <SocialNav />
          </div>

          <Nav />
        </div>
      </Container>
    </header>
  );
}
