import type { StaticImageData } from 'next/image';
import Image from 'next/legacy/image';
import Link from 'next/link';

import styles from '../styles/NavStub.module.css';

type Props = {
  title: string;
  subtitle?: string;
  link: string;
  image: StaticImageData;
};

export default function NavStub(props: Props) {
  return (
    <Link href={props.link} className={styles.item}>
      <div>
        <Image
          alt={props.title}
          src={props.image}
          layout="fixed"
          objectFit="cover"
          width={72}
          height={56}
        />
      </div>

      <div>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.subtitle}>{props.subtitle}</div>
      </div>
    </Link>
  );
}
