import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import menuRoomBlackfriars from '../../public/images/menu-blackfriars-meeting-room.png';
import menuRoomLiverpoolst from '../../public/images/menu-liverpool-st-meeting-room.png';
import menuRoomMonument from '../../public/images/menu-monument-meeting-room.png';
import menuRoomStpauls from '../../public/images/menu-st-pauls-meeting-room.png';
import menuRoomWaterloo from '../../public/images/menu-waterloo-meeting-room.png';
import styles from '../styles/Nav.module.css';
import IconBurgerMenu from './icons/IconBurgerMenu';
import NavDropdown from './NavDropdown';
import NavStub from './NavStub';

export default function Nav() {
  const navRef = useRef<HTMLDivElement>(null);

  const [menuMeetingRoom1Open, setMenuMeetingRoom1Open] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<string>('hidden');
  const router = useRouter();

  function handleMeetingRoom1Click(event: any) {
    event.preventDefault();
    setMenuMeetingRoom1Open(!menuMeetingRoom1Open);
  }

  function openMobileMenu() {
    setMobileOpen(mobileOpen === 'hidden' ? 'block' : 'hidden');
  }

  function closeAll() {
    setMenuMeetingRoom1Open(false);
  }

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      if (
        mobileOpen
        && navRef.current
        && !navRef.current?.contains(event.target)
      ) {
        setMobileOpen('hidden');
      }

      if (
        menuMeetingRoom1Open
        && navRef.current
        && !navRef.current?.contains(event.target)
      ) {
        setMenuMeetingRoom1Open(false);
      }
    };

    document.addEventListener('click', checkIfClickedOutside);
    window.addEventListener('resize', closeAll);
    router.events.on('routeChangeStart', closeAll);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [
    menuMeetingRoom1Open,
    navRef,
  ]);

  return (
    <nav ref={navRef}>
      <button
        className={styles.menu_btn}
        onClick={openMobileMenu}
        type="button"
      >
        <IconBurgerMenu width={24} height={24} />
        <span>MENU</span>
      </button>

      <ul className={cn(mobileOpen, styles.menu)}>
        <li className={styles.rooms}>
          <Link href="/meeting-rooms/" onClick={handleMeetingRoom1Click}>London Meeting Rooms</Link>
          <NavDropdown
            isOpen={menuMeetingRoom1Open}
            className="rooms"
            main={
              <ul className={styles.main_ul}>
                <li>
                  <NavStub
                    title="Blackfriars"
                    subtitle="LONDON EC4Y"
                    link="/meeting-rooms/london-blackfriars/"
                    image={menuRoomBlackfriars}
                  />
                </li>
                <li>
                  <NavStub
                    title="St. Paul's"
                    subtitle="LONDON EC1A"
                    link="/meeting-rooms/london-st-pauls/"
                    image={menuRoomStpauls}
                  />
                </li>
                <li>
                  <NavStub
                    title="Liverpool St"
                    subtitle="LONDON EC2M"
                    link="/meeting-rooms/london-liverpool-street/"
                    image={menuRoomLiverpoolst}
                  />
                </li>
                <li>
                  <NavStub
                    title="Waterloo"
                    subtitle="LONDON SE1"
                    link="/meeting-rooms/london-waterloo/"
                    image={menuRoomWaterloo}
                  />
                </li>
                <li>
                  <NavStub
                    title="Monument"
                    subtitle="LONDON EC3R"
                    link="/meeting-rooms/london-monument/"
                    image={menuRoomMonument}
                  />
                </li>
              </ul>
            }
          />
        </li>
        <li>
          <Link className={styles.highlight}
            href="https://osit.officernd.com/public/calendar/"
            target="_blank"
            rel="noopener"
          >
            Online Booking
          </Link>
        </li>
        <li>
          <Link href="/contact/" className={styles.highlight}>Contact us</Link>
        </li>
      </ul>
    </nav>
  );
}
